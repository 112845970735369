import { Component, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { csvToArr, csvSupplierData } from './csvSupplierData';
import axios from 'axios';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('vme-supplier-test');

const emailUrlParam = 'email';
const zrNummerUrlParam = 'zrNummer';
// get object property and ignore case
const getObjProperty = (obj: any, prop: string) =>
  Object.entries(obj).find(([key]) => key.toLowerCase() === prop.toLowerCase())?.[1]; // would return pair ['key', 'value'] so need `1` index

// should have method on backen since here if send request to `api.vme...` then get CORS error and in `no-cors` mode always get 401 error see (https://stackoverflow.com/questions/57615599/fetch-endpoint-with-basic-auth-gives-401)

const authModule = namespace('auth');
const companyModule = namespace('company');

@Component({
  components: {},
})
export default class VmeSupplierTemp extends Vue {
  @authModule.Action('getVmeSupplierData')
  private actionGetVmeSupplierData!: any;
  @authModule.Action('getVmeApiUserId')
  private actionGetVmeApiUserId!: any;
  @authModule.Getter('azureUser')
  private azureUser!: any;

  @companyModule.Action('getCompaniesIframe')
  private actionGetCompaniesIframe!: any;

  @Ref('vme-supplier-markup')
  private vmeSupplierRef!: any;
  @Ref('vme-supplier-markup22')
  private refIframe!: any;

  lieferantId = '';
  vmeApiUserId = '';
  isEmailError = false;
  isEmailRequestWasSent = false;

  items: any = [
    {
      Name: 'test',
      ZRNummer: '000',
    },
  ];

  apiVmeHistory: Array<string> = [];
  linkNavCount = 0;
  clearSearchInput() {}

  get hasZrNummerInUrl() {
    return !!getObjProperty(this.$route.query, zrNummerUrlParam);
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  markup: any = '';
  markup2: any = '';

  loadIdByEmail() {
    const email = getObjProperty(this.$route.query, emailUrlParam);

   return new Promise((resolve, reject) => {
    this.actionGetVmeApiUserId(email)
    .then((result: any) => {
      console.log('actionGetVmeApiUserId result :>> ', result);
      // this.vmeApiUserId = result.data.user_UID;
      if(result.data && result.data[0]) this.vmeApiUserId = result.data[0]?.user_UID;
      this.isEmailRequestWasSent = true;
      resolve(result);
    })
    .catch((err: any) => {
      this.isEmailError = true;
      logger.error(err);
    })
  });

  }

  formatIsoToDot(value: string){
    let result = DateUtils.isoDateTimeStringToDotsDate(value);
    return result;
  }

  created() {
    console.log('this.$route.query :>> ', this.$route.query);
    console.log('this.$route.query EMAIL :>> ', getObjProperty(this.$route.query, emailUrlParam));
    this.loadIdByEmail()
      .then((result) => {
        if (this.hasZrNummerInUrl) {
          this.changeVmeLieferant(getObjProperty(this.$route.query, zrNummerUrlParam) + '');
        }
      })
      .catch((err) => {});

    console.log('this.azureUser from created hook :>> ', Object.assign({}, this.azureUser));
    //https://hasnode.byrayray.dev/convert-a-csv-to-a-javascript-array-of-objects-the-practical-guide
    // 2024-01-23 Replace CSV data with database data
    // const dataCsv = csvToArr(csvSupplierData);
    // console.log('data :>> ', dataCsv);
    // this.items = dataCsv;

    this.actionGetCompaniesIframe()
      .then((result: any) => {
        // console.log('created actionGetCompaniesIframe result :>> ', result);
        this.items = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });

    const header = document.querySelector('header');
    header?.remove();
    const leftMenu = document.querySelector('.left-menu.v-navigation-drawer');
    leftMenu?.setAttribute('style', 'display: none');
    const app: any = document.querySelector('.v-application--wrap');
    app.style.setProperty('background', 'white'); //  = 'white !important';

    // !! cannot send request from frontend because of CORS (browser security), there are only 2 options to workaround browser CORS: 1) add our domain in white-list on server (`https://api.vme-einrichten.de`) 2) send requests not directly from browser, but via web server (.NET backend in our case) (from here https://www.reddit.com/r/learnjavascript/comments/zo4tb3/problem_with_fetch_auth_and_nocors/?rdt=45479)
    // var username = 'vme';
    // var password = 'swordfish'

    // const token = `${username}:${password}`;
    // const encodedToken = btoa(token);
    // const session_url = 'https://api.vme-einrichten.de/request/26dd2ece3feb8ec00dddec3d351350f9/konditionen?lieferant=862161';

    // var config = {
    //   method: 'get',
    //   url: session_url,
    //   headers: { 'Authorization': 'Basic '+ encodedToken }
    // };

    // const options = {
    //   method: 'GET',
    //   mode: 'no-cors'
    // };

    // axios(config)
    // .then((response) => {
    //   console.log(JSON.stringify(response.data));
    //   const resultWithAbsoluteUrl = response.data.replaceAll('href="/','href="https://vme:swordfish@api.vme-einrichten.de/')
    //   this.markup2 = resultWithAbsoluteUrl;
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
  }
  mounted() {
    console.log('window :>> ', window);
    this.vmeSupplierRef.onclick = (e: any) => {
      e.preventDefault();

      console.log('click on :>> ', e);
      console.log('click on  e.target:>> ', e.target);
      if (e.target.tagName.toLowerCase() == 'a' && !!e.target?.href) {
        console.log('href :>> ', e.target.href);
        const correctHref = new URL(e.target.href);

        let mainUrlPart = 'https://api.vme-einrichten.de';
        // change link for files according to link from `iframe`
        if (e.target.href.includes('download.php')) {
          mainUrlPart = `https://api.vme-einrichten.de/request/${this.vmeApiUserId}/kondition`;
        }

        const targetUrl = `${mainUrlPart}${correctHref.pathname + correctHref.search}`;
        this.actionGetVmeSupplierData(targetUrl)
          .then((result: any) => {
            console.log('result :>> ', result);
            this.apiVmeHistory.push(targetUrl);
            this.linkNavCount++;
            // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
            const resultWithAbsoluteUrl = result.data.replaceAll('onclick=history.back()', '');
            this.markup = resultWithAbsoluteUrl;
          })
          .catch((err: any) => {});
      }

      if (e.target.value?.includes('Zurück')) {
        if (this.linkNavCount == 0) {
          console.log('no history navigation (1st page) :>> ');
          return;
        }
        const targetUrl = this.apiVmeHistory[this.linkNavCount - 1];
        this.actionGetVmeSupplierData(targetUrl)
          .then((result: any) => {
            console.log('result :>> ', result);
            this.apiVmeHistory.pop();
            this.linkNavCount--;
            // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
            const resultWithAbsoluteUrl = result.data.replaceAll('onclick=history.back()', ''); //.replaceAll('href="/','v-on:click.prevent="showResponder(responder)" href="https://api.vme-einrichten.de/')
            this.markup = resultWithAbsoluteUrl;
          })
          .catch((err: any) => {});
      }
    };

    //  console.log('this.refIframe.contentWindow.onclick :>> ', this.refIframe.contentWindow.onclick);
  }

  changeVmeLieferant(value: string) {
    console.log('changeVmeLieferant value :>> ', value);
    // console.log('vmeSupplierRef  111 :>> ', this.vmeSupplierRef);
    console.log('changeVmeLieferant this.isEmailRequestWasSent :>> ', this.isEmailRequestWasSent);
    if (!this.vmeApiUserId && this.isEmailRequestWasSent) {
      logger.error(`NO ID for such an email [${getObjProperty(this.$route.query, emailUrlParam)}]`);
      this.isEmailError = true;
      return;
    }

    if (!this.isEmailRequestWasSent) {
      this.loadIdByEmail();
    }

    setTimeout(() => {
      this.isEmailError = false;
      this.loadSupplierData(value);
    }, 700);
  }

  loadSupplierData(value: string) {
    const initUrl = `https://api.vme-einrichten.de/request/${this.vmeApiUserId}/konditionen?lieferant=${value}`;

    this.actionGetVmeSupplierData(initUrl)
      .then((result: any) => {
        console.log('result :>> ', result);
        this.apiVmeHistory.push(initUrl);
        // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
        const resultWithAbsoluteUrl = result.data.replaceAll('onclick=history.back()', ''); //.replaceAll('href="/','href="https://api.vme-einrichten.de/')
        this.markup = resultWithAbsoluteUrl;
        console.log('this.vmeSupplierRef from change VME 222 :>> ', this.vmeSupplierRef);

        // this.showResponder(); // TODO: try to implement ignore and delete iframe approach (now cannot catch iframe click event)

        // console.log('this.$refs :>> ', this.$refs);
      })
      .catch((err: any) => {});
  }

  showResponder() {
    // console.log('this.refIframe :>> ', this.refIframe);

    this.refIframe.addEventListener(
      'click',
      function (event: any) {
        console.log('eevnet :>> ', event);
      },
      false
    );
    window[0].document.onclick = (e: any) => {
      e.preventDefault();
      console.log('cleick on :>> ', e);
      if (e.target.tagName.toLowerCase() == 'a') {
        console.log('href :>> ', e.target.href);
        // const correctHref = new URL(e.target.href);
        // const targetUrl = `https://api.vme-einrichten.de${correctHref.pathname}`;
        // this.actionGetVmeSupplierData(targetUrl).then((result:any) => {
        //   console.log('result :>> ', result);
        //   // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
        //   const resultWithAbsoluteUrl = result.data;//.replaceAll('href="/','v-on:click.prevent="showResponder(responder)" href="https://api.vme-einrichten.de/')
        //   this.markup = resultWithAbsoluteUrl;
        //  }).catch((err:any) => {

        // });
      }
    };
    console.log('window :>> ', window);
    const xx = (document.getElementById('myiframe') as any).contentWindow!.document;
    console.log('xx :>> ', xx);

    xx.addEventListener(
      'click',
      function (event: any) {
        console.log('eevnet :>> ', event);
      },
      false
    );

    this.refIframe.contentWindow.onclick = (e: any) => {
      e.preventDefault();
      console.log('cleick on :>> ', e);
      if (e.target.tagName.toLowerCase() == 'a') {
        console.log('href :>> ', e.target.href);
        // const correctHref = new URL(e.target.href);
        // const targetUrl = `https://api.vme-einrichten.de${correctHref.pathname}`;
        // this.actionGetVmeSupplierData(targetUrl).then((result:any) => {
        //   console.log('result :>> ', result);
        //   // (AD-80) need to replace relative URL to work in `iframe` with `srcdoc` attribute
        //   const resultWithAbsoluteUrl = result.data;//.replaceAll('href="/','v-on:click.prevent="showResponder(responder)" href="https://api.vme-einrichten.de/')
        //   this.markup = resultWithAbsoluteUrl;
        //  }).catch((err:any) => {

        // });
      }
    };
  }
}

// Example of markup returned from https://api.vme-einrichten.de/request/26dd2ece3feb8ec00dddec3d351350f9/konditionen
/*`
<script language="JavaScript" type="text/javascript">
<!--
function toggleClassVisibility(cls)
{
   var elems = document.getElementsByClassName(cls);
   var i = 0;
   for (i = 0; i < elems.length; i++)
   {
       if (elems[i].style.display == "none")
       {
          elems[i].style.display = "";
       }
       else
       {
          elems[i].style.display = "none";
       }
   }
}



function magnify(imagefile, horizontal, vertical)
{
   var ratio = horizontal / vertical;

   var new_horizontal = horizontal;
   var new_vertical   = vertical;

   if (horizontal > 1000)
   {
      new_horizontal = 1000;
      new_vertical   = new_horizontal / ratio;
   }


   var wincontent = imagefile;
  // wincontent = new_horizontal+" "+new_vertical;
  // wincontent = "<img width=100% src="+imagefile+">";

   var winlogicalname = "detailimage";

   var winparams      = "dependent=yes,locationbar=no,menubar=yes,resizable=no,scrollbars=yes,screenX=50,screenY=50,width=" + new_horizontal + ",height=" + new_vertical;

  // -- so geht es...
  // detailWindow = window.open(wincontent, winlogicalname, winparams);
  // detailWindow.document.write("<img src=\'"+imagefile+"\' width=100%>");

  // -- so geht es auch...
  // detailWindow = window.open("", winlogicalname, winparams);
  // detailWindow.document.all.inhalt.innerHTML = "<img src=\'"+imagefile+"\' width=100%>";

  // -- aber besser so...
   detailWindow = window.open("", winlogicalname, winparams);
   detailWindow.document.write("<div id=inhalt></div>");
   var inhalt = detailWindow.document.getElementById("inhalt");
   inhalt.innerHTML = "<img src=\'"+imagefile+"\' width=100%>";
  // <<

   detailWindow.creator = self;

   detailWindow.focus();
}


function openWindow(url, name, w, h)
{
   var breite = 380;
   var hoehe  = 380;

   if (w > 0)   breite = w;
   if (h > 0)   hoehe  = h;

   winparams = "menubar=yes,scrollbars,resizable,width=" + breite + ",height="+hoehe+",screenX=50,screenY=50";
   detailWindow = window.open(url, name, winparams);
   detailWindow.focus();
}

function openNakedWindow(url, name, w, h)
{
   var breite = 380;
   var hoehe  = 380;

   if (w > 0)   breite = w;
   if (h > 0)   hoehe  = h;

   winparams = "menubar=no,navigationbar=no,scrollbars,resizable,width=" + breite + ",height="+hoehe+",screenX=50,screenY=50";
   detailWindow = window.open(url, name, winparams);
   detailWindow.focus();
}

function slideShow(url, name)
{
   var breite = 850;
   var hoehe  = 650;

   winparams = "menubar=yes,scrollbars,resizable,width=" + breite + ",height=" + hoehe + ",screenX=30,screenY=30";
   detailWindow = window.open(url, name, winparams);
   detailWindow.focus();
}

//
-->
</script>


<!-- Google Tag Manager -->
<script>
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TNKVSQ3');
</script>
<!-- End Google Tag Manager -->


<HTML>

<HEAD>
<TITLE>Konditionsliste f&uuml;r 825204
</TITLE>
<meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1">
<link rel="stylesheet" type="text/css" href="/STYLE.CSS">
</HEAD>

<BODY id="wholepage" bgcolor="#FFFFFF" TEXT="#000000" LINK="#215DC6" VLINK="#4400AA" ALINK="#669966">
<TABLE class="rounded bordered" cellspacing=0 cellpadding=2 width=100%>
  <TR>
    <TD bgcolor=#E6E6E6 valign=bottom>&nbsp;Konditionsliste f&uuml;r 825204 (12.12.2023)</TD>
    <TD bgcolor=#E6E6E6 valign=top align=right>
      480009&nbsp&nbsp&nbsp<input type=button class="bordered rounded" style='line-height: 25px; background: #ee7f00; color: white; position: relative; top: -4px;' onclick=history.back() value="&lt;&lt;&nbsp;Zur&uuml;ck"> &nbsp;<img style='position: relative; top: 4px; right: 0;' class="bordered rounded" onclick="window.print();" src=/clipart/printer.png width=24px alt="Drucken">&nbsp;&nbsp;&nbsp;    </TD>
  </TR>
</TABLE>
<br><br><b>Adresse</b><br>
<table class="rounded bordered" cellspacing=0 cellpadding=2 width=100%>
  <TR>
    <th bgcolor=#E6E6E6 align=left>ZR-Nr</th>
    <th bgcolor=#E6E6E6 align=left>Suchname</th>
    <th bgcolor=#E6E6E6 align=left>Anschrift</th>
    <th bgcolor=#E6E6E6 align=left>Kommunikation</th>
    <th bgcolor=#E6E6E6 align=left>ZR-Status</th>
  <tr>
    <td valign=top>825204</TD>
    <td valign=top>HIMOLLA</td>
    <td valign=top>Himolla Polsterm&ouml;bel GmbH<br>
      <br>Landshuter Str. 38<br><br>D 84416 Taufkirchen/Vils</td>
    <td valign=top>Telefon: 08084 / 2 50<br>Telefax: 0808425558<br>EMail: info@himolla.com<br></td>
    <td valign=top>ZR vom 01.01.1980 bis 31.12.2099<br>(normal)</td>
  </tr>
</table>
<br><b><a href="#start"></a><a name="konditionen">Konditionen</a></b><br>
<tr>
  <table class="rounded bordered" cellspacing=0 cellpadding=2 width=100%>
    <td bgcolor=#E6E6E6 align=left><b>G&uuml;ltig f&uuml;r</b></td>
    <td bgcolor=#E6E6E6 align=left><b>G&uuml;ltig ab</b></td>
    <td bgcolor=#E6E6E6 align=left><b>Stand vom</b></td>
    <td bgcolor=#E6E6E6 align=left><b>Vertrag zur&uuml;ck am</b></td>
    <td bgcolor=#E6E6E6 align=left><b>&Auml;nderung betraf</b></td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313538091&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.06.2021 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>22.03.2021</td>
  <td bgcolor=#FFFFFF valign=top>22.03.2021</td>
  <td bgcolor=#FFFFFF valign=top>s. Skonto&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313538030&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>08.02.2021 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>08.02.2021</td>
  <td bgcolor=#FFFFFF valign=top>08.02.2021</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbevereinb. Aktionszeitr. 2021 (An. Logistik)&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313538075&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2021 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>17.03.2021</td>
  <td bgcolor=#FFFFFF valign=top>18.03.2021</td>
  <td bgcolor=#FFFFFF valign=top>s. Anhang Logistik&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313538016&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2021 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>20.01.2021</td>
  <td bgcolor=#FFFFFF valign=top>20.01.2021</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbungvereinbarung 2021&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313537953&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2021 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>21.12.2020</td>
  <td bgcolor=#FFFFFF valign=top>28.12.2020</td>
  <td bgcolor=#FFFFFF valign=top>s. Lieferung, Einzelstaffel, Direktbonus&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313537412&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2020 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>28.11.2019</td>
  <td bgcolor=#FFFFFF valign=top>28.11.2019</td>
  <td bgcolor=#FFFFFF valign=top>s. Einzelstaffel&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313537401&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2020 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>05.11.2019</td>
  <td bgcolor=#FFFFFF valign=top>05.11.2019</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbevereinbarung 2020 Änderung&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313537396&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2020 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>31.10.2019</td>
  <td bgcolor=#FFFFFF valign=top>30.10.2019</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbevereinbarung 2020&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313536760&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.01.2019 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>17.07.2018</td>
  <td bgcolor=#FFFFFF valign=top>17.07.2018</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbevereinbarung 2019&nbsp;</td>
</tr>
<tr>
  <td bgcolor=#FFFFFF valign=top><a
      href="/request/26dd2ece3feb8ec00dddec3d351350f9/kondition/sid=1702386196.7934&domain=vme-einrichten.de&konditionsid=313536738&lieferantnr=825204&aktuell=0&mitglied=825204&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,">Warengruppe
      4</td>
  <td bgcolor=#FFFFFF valign=top>01.04.2018 Rechnung</td>
  <td bgcolor=#FFFFFF valign=top>10.07.2018</td>
  <td bgcolor=#FFFFFF valign=top>10.07.2018</td>
  <td bgcolor=#FFFFFF valign=top>s. Werbevereinbarung 2018, 4x Mal im Jahr statt 3x&nbsp;</td>
</tr>
</table>
<a href=/request/26dd2ece3feb8ec00dddec3d351350f9/ekonditionen/sid=1702386196.7934&domain=vme-einrichten.de&action=KONDITIONSBLATT&vliefnr=825204&bliefnr=825204&jahr=2023&mitglied=480009&user=480009-cke&profil=admin,chef,kond,kondbonus,konddirbonus,logistik,ek,ekfs,vk,zr,st,spot,um,&externalmail=>Aktuelles
  Konditionsblatt drucken</a> <br>
</BODY>

</HTML>
`
*/
