import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';

@Component({ name: 'tree-navigator' })
export default class TreeNavigator extends Vue {
  @Prop({ default: () => [] })
  breadcrumbs!: any;

  @Prop() isDataChanged!: boolean;
  modelIsDataChanged: boolean = false;
  @Watch('isDataChanged')
  onIsDataChangedUpdated(newVal: boolean) {
    this.modelIsDataChanged = newVal;
  }

  @Prop({ default: false })
  expanded!: boolean;
  expandedModel: boolean = false;
  @Watch('expanded')
  onExpandedChanged(newVal: boolean) {
    this.expandedModel = newVal;
  }

  @Prop()
  value!: any;
  modelValue: any = undefined;

  @Watch('value', { deep: true })
  onValueChange() {
    this.initValue();
  }

  @Watch('modelValue', { deep: true })
  onValueChanged(newVal: any) {
    if (this.isDataChanged) this.$emit('value:update', newVal);
  }

  @PropSync('allowAdd', { default: false })
  modelAllowAdd!: boolean;
  @PropSync('showDialog', { default: false })
  modelShowDialog!: boolean;

  @Prop({ default: 'white' })
  private color?: string;

  @Prop()
  private image!: {
    src: string;
    header: string;
    height: number;
    width: number;
    small?: boolean;
  };

  private initValue() {
    this.modelValue = Object.assign({}, this.value);
  }

  add() {
    this.$emit('action:add');
  }
  showDialogClick() {
    this.$emit('show:dialog');
  }

  update() {
    this.$emit('value:update', this.modelValue);
  }

  reset() {
    this.$emit('value:reset');
  }

  created() {
    this.modelValue = this.value;
    this.expandedModel = this.expanded;
    this.modelIsDataChanged = this.isDataChanged;
  }
}
